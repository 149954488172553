/* eslint-disable react/jsx-key */
import React from 'react'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import { allScenes } from '../../data/les-scenes'
import { DynamicStaticImage } from '../../components/ArtistAtSchool/dynamicStaticImage'
import WhiteButton from '../../components/Ui/WhiteButton'

let colorIndex = 0
const getNextColor = () => {
  const bgArray = ['bg-yellow-wolf2025', 'bg-blue-wolf2025', 'bg-pink-wolf2025']
  return bgArray[(colorIndex++) % bgArray.length]
}

const Scenes = () => {
  const metaTitle = 'Scènes et sites | Le Festif! de Baie-Saint-Paul'
  const metaKeywords = 'festival, musique, évènement, spectacles, québec, charlevoix, scène'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader title="Scènes et sites" />
      <div className="container content pb-12">

        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:!mt-[2rem]">

            { allScenes?.map((scene, i) => {
              let link = '/scenes/' + scene.id
              link = scene.id === 'parvis' ? '/infos/parvisdufestif#info_content' : link
              link = scene.id === 'spectacles-imprevisibles-loto-quebec' ? '/infos/imprevisibles#info_content' : link
              link = scene.id === 'rue-festive' ? '/infos/ruefestive#info_content' : link
              return (
                <a 
                  key={i} 
                  className="text-center z-0 group relative w-full max-h-64 overflow-hidden border-purple-wolf2025 border-[3px] border-x-[3px] rounded-3xl"
                  href={link}>

                  <div className="overflow-hidden">
                    <DynamicStaticImage localPath={scene.getImageLocalPath} alt={scene.name}/>
                  </div>

                  <div className={`absolute bottom-0 w-full border-t-[3px] border-purple-wolf2025 transition-all duration-300 flex flex-col items-center overflow-hidden 
                    h-1/4 group-hover:h-1/2 ${getNextColor()}`}>

                    <div className="flex items-center justify-center h-full transition-all duration-300 !py-0 !my-0">
                      <h2 className="!font-black !font-obviously !text-purple-wolf2025 !text-xs uppercase tracking-wider !leading-4 !px-2 text-center !py-0 !my-0">
                        {scene.name}
                      </h2>
                    </div>

                    <div className="opacity-0 w-full h-0 group-hover:opacity-100 group-hover:h-2/5 transition-all duration-300 flex flex-col justify-center !py-0 !my-0">
                      <WhiteButton text="Plus d'infos" 
                                    className="mx-auto px-2 mb-3 h-auto !text-purple-wolf2025" 
                                    innerClassName="!text-purple-wolf2025 !no-underline !text-xs !font-obviously !font-regular" />
                    </div>

                  </div>

                </a>

              )
            })}

        </section>

      </div>
    </Wolflayout>
  )
}

export default Scenes
